import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const ProductCard = styled.div`
  position:relative;
  background-color:#fff;
  border-radius:15px;
  margin-bottom:30px;
  @media(min-width:992px){
    margin-bottom:20px;
  }
`
const ProductBody = styled.div`
  position:relative;
`
const ProductFigure = styled.div`
  .gatsby-image-wrapper{
    border-radius:15px;
    img{
      transform-origin: 65% 75%;
      transition: transform 1s,filter 0.5s ease-out;
      &:hover{
        transform: scale(1.1);
      }
    }
  } 
  &:hover{
    .media-hover{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
    }
  }
`
const ProductSku = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
  background-color: #C20000;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  line-height:26px;
  font-weight: 700;
  padding: 0 10px;
  &:after{
    position: absolute;
    content: '';
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 26px 0 0 15px;
    right: -15px;
    border-color: transparent #C20000;
  }
`
const ProductTitle = styled.div`
    position: absolute;
    left:50%;
    transform:translateX(-50%);
    bottom:0;
    text-align: center;
    padding: 7px 10px;  
    width:calc(100% - 30px);
    @media(min-width:1440px){
      width:calc(100% - 60px);
      padding: 7px 20px;  
    }

  > span{
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    color:#131413;
    font-size: 16px;
    line-height: 24px;
    @media(min-width:576px){
      font-size: 18px;
      line-height: 26px;
    }
    @media(min-width:1440px){
      font-size: 20px;
    }
  }
  &:before{
    content: "";
    border-radius: 15px 15px 0 0;
    background-color: rgba(255,255,255,0.3);
    border: 1px solid #f5f5f5;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
`
const ProductCards = ({ sku, data }) => {
  return (
    <ProductCard className="card">
      <ProductBody className="card-body">
        <ProductSku>SKU : PANM#{sku}</ProductSku>
        <ProductFigure className="card-figure">
            <GatsbyImage image={data.image.gatsbyImageData} alt={data.name} />
        </ProductFigure>
        <ProductTitle className="card-title"><span>{data.name}</span></ProductTitle>
      </ProductBody>
    </ProductCard>
  )
}

export default ProductCards
