import styled from 'styled-components'

export const Banner = styled.div`	
  position: relative;
  margin:0;
  padding:0;
  @media(min-width:992px){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`
export const BannerLeft = styled.div`	
  position: relative;
  height: 100%;

  padding:120px 20px 40px 20px;
  @media(min-width:992px){
    padding:160px 30px 120px 30px;
  }
  @media(min-width:1640px){
    padding: 220px 160px 120px calc(50vw - 790px);
  }

`
export const BannerRight = styled.div`	
  position:relative;
  background:#002765;
  text-align: center;

  padding:40px 20px 80px 20px;
  @media(min-width:992px){
    padding:160px 30px 120px 30px;
  }
  @media(min-width:1640px){
    padding: 220px calc(50vw - 790px) 120px 160px;
  }
`
export const BannerTitle = styled.h1`	
  strong, span{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    letter-spacing: -0.5px;  
    
    font-size: 40px;
    line-height: 1.2;
    @media (min-width: 768px) {
      font-size: 50px;
    }
    @media (min-width: 992px) {
      font-size: 60px;
    }
    @media (min-width: 1200px) {
      font-size: 70px;
    }
    @media (min-width: 1600px) {
      font-size: 80px;
    }
  }
  strong{
    background-color:#161420;
    color: #fff;
    -webkit-background-clip: text;
    -webkit-text-stroke: 4px transparent;

    letter-spacing: 5px;
    text-transform: uppercase;
    position: relative;
    padding: 0 1px;
    &:before{
      content:'';
      position:absolute;
      top:0;
      left:0;
      background-color:#C20000;
      width:36px;
      height:2px;
    }
  }
  span{
    color:#002765;
    text-transform:uppercase;
    position:relative;
  }
`
export const BannerLead = styled.p`	
    display:inline-block;
    position:relative;
    text-transform:uppercase;
    letter-spacing: -0.5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    line-height: 32px;
    margin-bottom:30px;
    margin-bottom:10px;
    padding-bottom:5px;
    @media (min-width: 992px) {    
        font-size: 26px;
        line-height: 36px;
    }
    @media (min-width: 1200px) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom:20px;
        padding-bottom:10px;
    }
    &:after{
      content: '';
      position:absolute;
      left:0;
      bottom:0;
      width:80%;
      height:1px;
      background-color:#161420;
    }
`
export const BannerDesc = styled.div`	
  p{
    margin-bottom:0;
    + p{
      margin-top:15px;
      @media(min-width:1200px){
        margin-top:30px;
      }
    }
    @media(min-width:1600px){
      font-size:20px;
      line-height:34px; 
    }
    a{
      position:relative;
      &:after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom:-1px;
        display: block;
        height: 1px;
        transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
        transition: transform .4s cubic-bezier(.77,0,.175,1);
        transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
        transform: translateZ(0) scaleX(1);
        background: #002765;
        transform-origin: right center;    
        transform: translateZ(0) scaleX(0);
      }
      &:hover{
        color:#002765;
        &:after {
          transform-origin: left center;        
          transform: translateZ(0) scaleX(1);
        }  
      }
    }
  }
`
export const BannerCard = styled.div`	
  position: relative;
  background-color:#fff;
  border-radius:15px;
  box-shadow:25px 25px 45px rgba(0,0,0,0.35);
  width:100%;
  padding:20px;
  @media(min-width:768px){
    padding:30px;
  }
  @media(min-width:992px){
    max-width:698px;
  }
  @media(min-width:1200px){
    padding:45px;
  }
`
