import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { ColorBucketIcon } from "../Icons"
import styled from "styled-components"

const ColorPick = styled.div`
    margin:0;
`
const ColorImage = styled.div`
    position:relative;
    padding:30px 0;
    .gatsby-image-wrapper{
        z-index:1;
    }
    &:after, &:before{
       
        position: absolute;
        text-transform:uppercase;
        font-weight: 700;

        font-size: 40px;
        line-height: 40px;
        display:none;
        @media(min-width:1440px){
            display:block;
            font-size: 50px;
            line-height: 50px;
        }
        @media(min-width:1600px){
            font-size:70px;
            line-height:70px;
        }
    }
    &:before{
        content: attr(data-before-label);
        color:#E3E8F1;
        top: 15%;
        @media(min-width:1440px){
            left: 0;
        }
        @media(min-width:1600px){
            left: -50px;
        }
    } 
    &:after{
        content: attr(data-after-label);
        color:#fff;            
        bottom: 22%;
        @media(min-width:1440px){
            right:-40px;
        }
        @media(min-width:1600px){
            right: -120px;
        }
    } 
`
const ColorList = styled.div`
    display:flex;
    flex-wrap:nowrap;
    justify-content: center;
    overflow-x:auto;
    overflow-y:hidden;
    white-space:nowrap;
    padding-top:16px;
    justify-content: flex-start;
    /* margin:0 -10px;
    @media(min-width:1200px){
        margin:0 -15px;
    } */
`
const ColorItem = styled.div`
    position:relative;
    width:100%;
    cursor:pointer;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    margin-bottom:30px;
    padding:0 10px;
    @media(min-width:1366px){
        padding:0 15px;
    }
    .icon{
        > svg{
            transform: perspective(1px) translateZ(0);
            transition-duration: 0.3s;
            transition-property: transform;
        }
    }
    .code{
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: transform;
    }
    .code-name{
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        display: block;
    }
    &:hover, &.selected{
        .icon{
            > svg{
                fill:#161420;
                transform: translateY(-15px) rotate(45deg);
            }
        }
        .code{
            transform: translateY(-15px);
        }
        .code-name{
            opacity: 1;
            transform: translate(-50%, -10px);
        }
    }
`
const ColorIcon = styled.div`
    text-align:center;
    > svg{
        fill:#B6C4D9;
    }
`
const ColorCode = styled.div`
    width:45px;
    height:45px;
    border-radius:50%;
    border:1px solid #fff;
    background-color:${props=>props.bkgColor};
    box-shadow:0 8px 30px rgba(0,0,0,0.15);
    transition:all 0.5s ease-in-out;
    margin: 0 auto;
`
const ColorName = styled.div`
    color: #161420;
    text-align: center;
    letter-spacing: 0.5px;
    
    opacity: 0;
    transition-duration: 0.3s;
    transition-property: transform, opacity;
    line-height: 18px;
    font-weight: 600;
    font-size: 12px;
    @media(min-width:1366px){
        font-size: 14px;
    }
    > span{
        white-space: nowrap;
    }
`


const ColorPicker = () => {
  const [currentColor, setCurrentColor] = useState("White");
  const data = useStaticQuery(graphql`
    query ColorPickerQuery {
      allContentfulColor(sort: {fields: order}) {
        edges {
          node {
            colorName
            colorHexValue
            coloredImage {
              gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
          }
        }
      }
    }
  `)
  const colors = data.allContentfulColor.edges;
  return (
    <ColorPick>
      {colors.map((item, i)=> 
        <>
          { item.node.colorName === currentColor && (
            <ColorImage data-before-label="Custom" data-after-label="Building">
              <GatsbyImage image={item.node.coloredImage.gatsbyImageData} alt={item.node.colorName} />
            </ColorImage>
          )}
        </>
      )}
      <ColorList>
          {colors.map((item, i)=>
              <ColorItem key={i} onClick={() => setCurrentColor(item.node.colorName)}>
                  <ColorIcon className="icon"><ColorBucketIcon /></ColorIcon>
                  <ColorCode className="code" bkgColor={item.node.colorHexValue}></ColorCode>
                  <ColorName className="code-name"><span>{item.node.colorName}</span></ColorName>
              </ColorItem>
          )}
      </ColorList>
  </ColorPick>
  )
}

export default ColorPicker
