import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import PrimaryButton from "../Button/PrimaryButton"
import { LineArrowRightIcon } from "../Icons"
import { Context } from "../context";

const CallQuote = styled.div`
  position:relative;
  width:100%;
  color:#fff;
  text-align:center;
  @media(min-width:1200px){
    max-width:610px;
    margin-left: auto;
    text-align:left;
  }
  strong{
    display:block;
    font-weight:400;
    font-size: 22px;
    line-height: 32px;
    margin-bottom:10px;
    color:#131413;
    @media (min-width: 992px) {    
      font-size: 26px;
      line-height: 36px;
    }
    @media (min-width: 1200px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  a{
    display:block;
    color:#131413;
    margin-bottom:20px;
    @media (min-width: 992px) {
      margin-bottom:30px;
      font-size: 60px;
      line-height: 70px;
    }
    @media (min-width: 1200px) {
      margin-bottom:38px;
    }
    @media (min-width: 1600px){
      font-size: 70px;
      line-height: 80px;
    }
    &:hover{
      color:#d80c0c;
    }
  }
`
const CallExpert = () => {
    const [popupContext, setPopupContext] = useContext(Context);

    const HandleModalOpen = () => {
      setPopupContext(true)
    }
    useEffect(() => {        
        if (popupContext===true) {
            document.querySelector("body").classList.add("modal-open")
        } else {
            document.querySelector("body").classList.remove("modal-open")
        }
    }, [popupContext, setPopupContext])
    return (
        <CallQuote>
            <strong>Call Our Building Experts Today</strong> <a className="h1" href="tel:8067314816">(806) 731-4816</a>
            <button type="button" aria-label="button" onClick={HandleModalOpen}><PrimaryButton iconspace="icon-space" text="Get a Quote" iconAfter={<LineArrowRightIcon />}/></button>
        </CallQuote>
    )
}

export default CallExpert